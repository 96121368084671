<template>
  <div class="container">
    <div class="foot pc">
      <span class="fotbtn" @click="pageScroll"><i class="el-icon-arrow-up"></i></span>
      <ul class="fonav">
        <li> <a class="inmenu" @click="jump('/about')">ABOUT US</a></li>
        <li> <a class="inmenu" @click="jump('/product')">PRODUCT</a></li>
        <li> <a class="inmenu_1" @click="jump('/contactUs')">CONTACT US</a></li>
        <li> <a class="inmenu" @click="jump('/feedBack')">FEEDBACK</a></li>
      </ul>
      <p class="fowor">Shenzhen Yagu Dental Technology Co., Ltd. <br>
      </p>
      <p>Contact：Lena</p>
      <p>Phone：+8613631613096</p>
      <p>Whatsapp：<a href="https://api.whatsapp.com/send?phone=8613631613096&amp;text=Hello" id="A_8">8613631613096</a>
      </p>
      Email：<a href="mailto:L91327@hotmail.com" id="A_9" target="_blank">L91327@hotmail.com</a><br>
      Address：Rm 1808 Tianle Building, 1021 Buji Road, Dongxiao Street,Luohu Disrtict, Shenzhen,Gungdong,China
      <p></p>
      <!-- <ul class="foshare">
        <li>
          <a href="https://www.linkedin.com/in/" target="_blank" rel="nofollow">
            <span class="iconfont">
              &#xee48;
            </span>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/home" target="_blank" rel="nofollow">
            <span class="iconfont">
              &#xe652;
            </span>
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/" target="_blank" rel="nofollow">
            <span class="iconfont">
              &#xe73d;
            </span>
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/profile.php" target="_blank" rel="nofollow">
            <span class="iconfont">
              &#xe6c2;
            </span>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com" target="_blank" rel="nofollow">
            <span class="iconfont">
              &#xe87f;
            </span>
          </a>
        </li>
      </ul> -->
    </div>
    <footer class="l-foot mobile">
      <div class="foot">
        <ul class="contact-list">
          <li>
            <i class="iconfont" style="font-size:28px; line-height: 28px;">
              &#xe6db;
            </i>
            <span class="text">Rm 1808 Tianle Building, 1021 Buji Road, Dongxiao Street,Luohu Disrtict, Shenzhen,Gungdong,China</span>
          </li>
          <li>
            <i class="iconfont">
              &#xe678;
            </i>
            <a href="tel:+8613631613096">+8613631613096</a>
          </li>
          <li>
            <i class="iconfont">
              &#xe918;
            </i>
            <a href="mailto:L91327@hotmail.com">L91327@hotmail.com</a>
          </li>
        </ul>
      </div>
      <div class="foot-nav">
        <ul>
          <li><a @click="jump('/about')" class="inmenu">About Us</a></li>
          <li><a @click="jump('/product')" class="inmenu">PRODUCT</a></li>
          <li><a @click="jump('/contactUs')" class="inmenu">CONTACT US</a></li>
          <li><a @click="jump('/feedBack')" class="inmenu">FEEDBACK</a></li>
        </ul>
      </div>
      <div class="foot-bar is-show">
        <ul>
          <li><a href="https://api.whatsapp.com/send?l=en&amp;phone=8613631613096" rel="nofollow"><i class="icon icon-whatsapp"></i>whatsapp</a></li>
          <li><a href="mailto:L91327@hotmail.com" rel="nofollow" id="A_9"><i class="icon icon-mail"></i>Email</a></li>
          <li><a @click="jump('/contactUs')" rel="nofollow"><i class="icon icon-inquiry"></i>Inquiry</a></li>
        </ul>
      </div>
      <div class="btn-top" @click="pageScroll"></div>
    </footer>
  </div>
</template>
<script>
export default {
  methods: {
    pageScroll () {
      document.documentElement.scrollTop = 0
    },
    jump (name) {
      this.$router.push(name)
    }
  }
}
</script>
<style scoped lang="scss">
/* @import url(@/assets/iconfont/iconfont.css); */
.container {
  max-width: 100%;
}
.pc {
  padding-bottom: 50px;
  .inmenu {
    cursor: pointer;
  }
}
.foot {
  margin-top: 80px;
  text-align: center;
  background: #171717;
  color: #747474;
  position: relative;
  padding-top: 110px;
}
.fotbtn {
    display: inline-block;
    width: 62px;
    height: 62px;
    background: #343434;
    line-height: 62px;
    cursor: pointer;
    color: #fff;
    position: absolute;
    top: -31px;
    left: 50%;
    margin-left: -31px;
}
.fotbtn i.iconfont {
  font-size: 20px;
}
.fonav li {
  display: inline-block;
  padding: 12px 28px;
  font-size: 18px;
}
.fonav li a {
  color: #747474;
  font-family: popmed;
}
.fonav li a:hover {
  color: #ed800a;
}
.fowor {
  font-family: popmed;
  display: inline-block;
  max-width: 610px;
  margin-top: 40px;
  font-size: 13px;
  padding: 0 18px;
}
.foshare {
  padding: 50px 0;
}
.foshare li {
  display: inline-block;
  padding: 0 8px;
}
.foot .foshare li a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.foshare li a {
  display: inline-block;
  width: 39px;
  height: 39px;
  border-radius: 50%;
  border: 1px solid #747474;
  line-height: 37px;
}
 .foshare li a .iconfont {
  font-size: 20px;
  color: #747474;
}
.foshare li a:hover {
  border-color: #ed800a;
  background: #ed800a;
}
.foot a {
  color: #747474;
}
a:hover {
  color: #ed800a;
}

.mobile {
  display: none;
}

@media screen and (max-width: 748px) {
  .pc {
    display: none;
  }
  .mobile {
    position: relative;
    display: block;
  }
}
.l-foot {
    // padding-bottom: calc(55px + env(safe-area-inset-bottom));
  .foot {
    background: #111;
    padding: 14px;
    .contact-list>li {
      display: flex;
      padding: 5px 0;
      z-index: 1;
      .iconfont {
        flex: 0 0 20px;
        height: 20px;
        width: 20px;
        font-size: 20px;
        line-height: 20px;
      }
      a, span {
        text-align: left;
        color: #707274;
        display: block;
        -ms-flex: 1;
        flex: 1;
        line-height: 1.3em;
        margin-left: 10px;
        font-size: 14px;
      }
    }
  }
  .foot-nav {
    background: #111;
    border-top: 1px solid #222;
    padding: 10px 1.5px;
  }
  .foot-nav>ul {
    text-align: center;
    list-style: none;
  }
  .foot-nav>ul>li {
    display: inline-block;
    font-size: 12px;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .foot-nav>ul a {
    color: #707274;
    line-height: 1.5;
    padding: 0 8px;
  }
  .foot-bar>ul, .foot-bar>ul>li {
    display: -ms-flexbox;
    display: flex;
  }
  .foot-bar>ul>li {
    -ms-flex-pack: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex: 1;
    flex: 1;
    height: 55px;
    justify-content: center;
    font-size: 12px;
    line-height: 170%;
    z-index: 1;
  }
  .foot-bar>ul>li a {
    font-size: 12px;
    color: #555;
  }
  .foot-bar .icon {
    background: url(@/assets/image/css_sprites.png);
    display: block;
    height: 25px;
    margin: 0 auto;
    width: 25px;
  }
  .foot-bar .icon-whatsapp {
    background-position: -200px 0px;
  }
  .foot-bar .icon-mail {
    background-position: -100px 0px;
  }
  .foot-bar .icon-inquiry {
    background-position: -150px 0px;
  }
  .btn-top {
    // cursor: pointer;
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: 100px;
    right: 30px;
    background: url(@/assets/image/top.png) #999 no-repeat;
    border-radius: 10%;
    overflow: hidden;
    background-position: center;
    z-index: 9999;
    // display: none;
  }
}
</style>
