import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/pages/home/Home'
import About from '@/pages/about/About'
import NotFound from '@/pages/notfound/NotFound'


import Product from '@/pages/product/index'
import ProductList from '@/pages/productList/index'
import ProductDetail from '@/pages/productDetail/index'
import ContactUs from '@/pages/contactUs/index'
import FeedBack from '@/pages/feedBack/FeedBack'

Vue.use(Router)

export default new Router({
  // mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    }, {
      path: '/product',
      name: 'product',
      component: Product,
      children: [
        { path: '/', component: ProductList },
        { path: '/productDetail', component: ProductDetail }
      ]
    }, {
      path: '/contactUs',
      name: 'contact-us',
      component: ContactUs
    }, {
      path: '/about',
      name: 'about',
      component: About
    }, {
      path: '/feedBack',
      name: 'feedBack',
      component: FeedBack
    }, {
      path: '*',
      name: 'NotFound',
      component: NotFound
    }
  ],
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})
