<template>
  <div>
    <Home-Nav></Home-Nav>
    <Home-title :navindex="4"/>
    <Home-banner title="ABOUT US"/>
    <Breadcrumb />
    <mainNav />
    <Home-Main />
    <Home-Footer></Home-Footer>
  </div>
</template>

<script>
import HomeNav from '../common/Nav'
import HomeTitle from '../common/Title'
import Breadcrumb from '../common/Breadcrumb'
import HomeMain from './components/Main'
import HomeBanner from '../common/HomeBanner'
import HomeFooter from '../common/Footer'
import mainNav from '../common/mainNav.vue'

export default {
  components: {
    HomeNav, HomeFooter, HomeTitle, HomeMain, Breadcrumb, HomeBanner, mainNav
  },
  data () {
    return {}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
