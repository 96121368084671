<template>
  <div>
    <div class="pro-detail pc">
      <zoomImgVue :imgList="productDetail.imageUrlList" />
      <div class="right_contaner">
        <h1>
          {{ productDetail.name }}
        </h1>
        <p>
          {{ productDetail.introduce }}
        </p>
        <div>
          <a style="cursor: pointer;" @click="sendInquiry" class="sendInquiry">Send Inquiry</a>
        </div>
        <div class="share-btn-list">
          <!-- <div class="share-btn facebook" data-type="facebook" title="Facebook" @click="documentClick('https://www.facebook.com/profile.php')"></div> -->
          <!-- <div class="share-btn twitter" data-type="twitter" title="Twitter" @click="documentClick('https://twitter.com/home')"></div> -->
          <!-- <div class="share-btn linkedin" data-type="linkedin" title="Linkedin" @click="documentClick('https://www.linkedin.com/in/')"></div> -->
          <!-- <div class="share-btn pinterest" data-type="pinterest" title="Pinterest" @click="documentClick('https://www.pinterest.se/login')"></div> -->
          <div class="share-btn whatsapp" data-type="whatsapp" title="Whatsapp" @click="documentClick('https://web.whatsapp.com')"></div>
          <div id="A_1" class="share-btn mail" data-type="mail" title="Email" @click="documentClick('mailto:L91327@hotmail.com')"></div>
          <!-- <div class="share-btn print" data-type="print" title="Print" @click="printClick"></div> -->
        </div>
      </div>
    </div>
    <div class="mobile">
      <el-carousel :interval="3000" arrow="none" :autoplay="false">
        <el-carousel-item v-for="(item, index) in productDetail.imageUrlList" :key="index">
          <img class="bannerimg" :src="item" />
          <!-- <h3 class="scrolling-text">{{ item }}</h3> -->
        </el-carousel-item>
      </el-carousel>
      <div class="main-sec product-top">
        <div class="main-tit">
          <h1>
            {{ productDetail.name }}
          </h1>
        </div>
        <div class="product-intro">
          {{ productDetail.introduce }}
        </div>
        <div>
          <a @click="sendInquiry" class="sendInquiry">Send Inquiry</a>
        </div>
      </div>
    </div>

    <div class="tagContent selectTag">
      <div v-for="(item, index) of productDetail.areaList" :key="index">
        <h4 style="width:100%;font-size:18px;border-bottom:2px solid #ffbc28">
          <span
            style="display:inline-block;padding:8px 20px;box-sizing:border-box;background-color:#ffbc28;color:#fff;margin-top:-1px;transform:skew(-20deg) translateX(8px)">
            <strong style="display:inline-block;transform:skew(20deg)">
              {{ item.title }}
            </strong>
          </span>
        </h4>
        <div>
          <div class="details" v-html="item.content"></div>
        </div>
      </div>
    </div>
    <div id="F1">
      <h3 class="feedBack">Send Inquiry</h3>
      <div name="F1">
        <sendEmailVue />
      </div>
    </div>
  </div>
</template>

<script>
import zoomImgVue from './components/zoomImg.vue';
import sendEmailVue from '../common/sendEmail.vue';
import { productDetail } from '@/api/home'

export default {
  components: {
    zoomImgVue,
    sendEmailVue
  },
  data () {
    return {
      productDetail: {}
    }
  },
  async created() {
    const id = JSON.parse(sessionStorage.getItem("productDetail"))
    await this.getDetail(id)
    if (!this.productDetail) {
      this.$router.push('/product')
    }
  },
  methods: {
    getDetail(id) {
      productDetail(id).then(res => {
        this.$store.dispatch('categoryLists/saveBreadcrumbPro', res.categoryList)
        this.productDetail = res

      })
    },
    documentClick(name) {
      var a = document.createElement('a')
      a.setAttribute('href', name)
      a.setAttribute('target', '_blank')
      document.body.appendChild(a)
      a.click()
    },
    printClick() {
      window.print()
    },
    sendInquiry () {
      document.getElementById('F1').scrollIntoView()
    }
  }
}
</script>

<style scoped lang="scss">
/deep/.el-carousel__container {
  height: 50vw;
  text-align: center;
  img {
    height: 100%;
  }
}
.pro-detail {
  padding-bottom: 30px;
  width: 100%;
  border-bottom: 1px dashed #aaa;
}
.pro-detail:after {
    content: '';
    display: block;
    visibility: hidden;
    height: 0;
    clear: both;
}
.right_contaner {
  float: left;
  margin-left: 40px;
  width: 50%;
  h1 {
    line-height: 30px;
    margin-bottom: 13px;
    font-family: latoreg;
    font-size: 25px;
    font-weight: bold;
  }
}
.sendInquiry {
  margin: 20px 14px 20px 0;
  display: inline-block;
  padding: 0 18px;
  line-height: 36px;
  font-size: 16px;
  background: #ed800a;
  color: #fff;
}
.selectTag {
  margin-top: 30px;
  display: block;
}

.tagContent {
  line-height: 1.6;
}
.tagContent {
  // display: none;
  width: 100%;
  color: #000;
  padding-top: 15px;
  font-size: 15px;
  line-height: 22px;
}
.details {
  /deep/ table{
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
  }
  /deep/ table td,
  table th {
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding: 3px 5px;
  }
  /deep/ table th {
    border-bottom: 2px solid #ccc;
    text-align: center;
  }

  /* blockquote 样式 */
  /deep/ blockquote {
    display: block;
    border-left: 8px solid #d0e5f2;
    padding: 5px 10px;
    margin: 10px 0;
    line-height: 1.4;
    font-size: 100%;
    background-color: #f1f1f1;
  }

  /* code 样式 */
  /deep/ code {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    background-color: #f1f1f1;
    border-radius: 3px;
    padding: 3px 5px;
    margin: 0 3px;
  }
  /deep/ pre code {
    display: block;
  }

  /* ul ol 样式 */
  /deep/ ul, ol {
    margin: 10px 0 10px 20px;
  }
}
  /* table 样式 */
//   table {
//     border-top: 1px solid #ccc;
//     border-left: 1px solid #ccc;
//   }

// }
:deep(.details img) {
    // width: 100%;
    // height: 100%;
}
.feedBack {
  margin-top: 20px;
  background: #ed800a;
  color: #fff;
  text-align: center;
  min-width: 150px;
  display: inline-block;
  margin-bottom: 15px;
  font-size: 17px;
  padding: 0 25px;
  font-weight: 500;
  line-height: 40px;
}

.main-sec {
  background: #FFF;
  margin-top: 12px;
  padding: 15px 15px 10px;
}
.main-tit {
  -ms-flex-pack: justify;
  border-bottom: 2px solid #E6E6E6;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
}
.main-tit>h1 {
  font-size: 22px;
  // color: #217FE1;
  font-weight: 400;
}
.product-intro {
    word-break: break-word;
}

.product-intro {
    color: #666;
    font-size: 15px;
    margin-bottom: 4px;
    padding: 10px 0;
}
.mobile {
  display: none;
  // visibility: hidden;
}

@media screen and (max-width: 1050px) {
  .mobile {
    display: block;
  }
  .pc {
    display: none;
  }
}
.share-btn-list {
    // width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
}

.share-btn-list .share-btn {
    position: relative;
    height: 30px;
    width: 30px;
    transition: .4s all;
    transition-delay: 30ms;
    cursor: pointer;
    overflow: hidden;
    border-radius: 2px;
}
.share-btn-list>div {
    margin-right: 6px;
}

.share-btn-list .facebook {
    background: url(@/assets/image/share-facebook.png) no-repeat;
    background-size: 30px 30px;
}
.share-btn-list .twitter {
    background: url(@/assets/image/share-twitter.png) -4px -4px no-repeat;
    background-size: 37px 37px;
}
.share-btn-list .linkedin {
    background: url(@/assets/image/share-linkedin.png) -4px -4px no-repeat;
    background-size: 36px 36px;
}
.share-btn-list .pinterest {
    background: url(@/assets/image/share-pinterest.png) -2px -2px no-repeat;
    background-size: 32px 32px;
}
.share-btn-list .whatsapp {
    background: url(@/assets/image/share-whatsapp.png) -2px -2px no-repeat;
    background-size: 34px 34px;
}
.share-btn-list .mail {
    background: url(@/assets/image/share-mail.png) center no-repeat #f90;
    background-size: 22px 22px;
}
.share-btn-list .print {
    background: url(@/assets/image/share-print.png) center no-repeat #2e3192;
    background-size: 18px 18px;
}
.share-btn-list .share-btn:hover {
    transform: translateY(-6px);
}
</style>