import axios from 'axios'
import { Message } from 'element-ui'

const service = axios.create({
  baseURL: '/',
  timeout: 5000
})

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    return config // 必须返回配置
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const { message, error: data, code } = response.data
    // 要根据success的成功与否决定下面的操作
    if (code === 1000) {
      return data
    } else {
      // 业务错误
      Message.error(message) // 提示错误消息
      return Promise.reject(new Error(message))
    }
  },
  (error) => {
    Message.error(error.message) // 提示错误信息
    return Promise.reject(error)
  }
)

export default service
