<template>
  <div class="pc" style="height:49px;">
    <header class="header clear">
      <nav class="navbar">
        <div class="head-top">
          <ul class="hecact">
            <li class="hectel">
              <el-tooltip
                class="item"
                content="+8613631613096"
                placement="bottom"
              >
                <i class="fa fa-phone"></i>
              </el-tooltip>
            </li>
            <li class="hecadd">
              <el-tooltip placement="bottom">
                <div slot="content">
                  <a
                    class="email"
                    href="mailto:L91327@hotmail.com"
                    id="A_2"
                    >L91327@hotmail.com</a
                  >
                </div>
                <i class="fa fa-envelope"></i>
              </el-tooltip>
            </li>
          </ul>
          <div class="hescroll ipslide">
            <scrollingText />
          </div>
          <div class="search">
            <input type="text" class="searchtxt" name="lname" v-model="productName">
            <input type="button" name="button" class="searchbtn" value="" @click="PSearch()" id="btnSearch">
          </div>
          <!-- <ul class="foshare">
            <li><a href="https://www.linkedin.com/in/" target="_blank" rel="nofollow"><span class="iconfont">&#xee48;</span></a></li>
            <li><a href="https://twitter.com/home" target="_blank" rel="nofollow"><span  class="iconfont">&#xe652;</span></a></li>
            <li><a href="https://www.youtube.com/" target="_blank" rel="nofollow"><span class="iconfont">&#xe73d;</span></a></li>
            <li><a href="https://www.facebook.com/profile.php" target="_blank" rel="nofollow"><span class="iconfont">&#xe6c2;</span></a></li>
            <li><a href="https://www.instagram.com/" target="_blank" rel="nofollow"><span class="iconfont">&#xe87f;</span></a></li>
          </ul> -->
        </div>
      </nav>
    </header>
    <onlineServiceVue />

  </div>
</template>
<script>
import scrollingText from './scrollingText'
import onlineServiceVue from './onlineService.vue'
export default {
  components: {
    scrollingText,
    onlineServiceVue
  },
  data () {
    return {
      productName: ''
    }
  },
  methods: {
    PSearch () {
      this.$router.push("/product?productName=" + this.productName)
    }
  }
}
</script>
<style scoped>
.clear::after {
  content: "";
  display: block;
  clear: both;
}
.fff {
  color: rgb(55, 57, 55) !important;
  pointer-events: none;
}
header {
  background: #f4f3f3;
  z-index: 99;
  height: 49px;
  /* color: rgba(255, 255, 255, 0.5); */
}
.navbar-brand {
  display: inline-block;
  padding: 16px 14px;
}
.header .navbar-brand {
  float: left;
  background-color: #fa541c;
}
.header .navbar-brand img {
  height: 32px;
  width: 152px;
  vertical-align: middle;
  border-style: none;
}
.menu {
  float: right;
}
.menu li {
  float: left;
  padding: 24px 17px;
  font-size: 15px;
  cursor: pointer;
}
header li:hover{
  color: rgb(237, 128, 10);
}
/* .downmenu li:hover {
  color: rgb(237, 128, 10);
} */
@media screen and (max-width: 496px) {
  .header .navbar-brand {
    float: left;
    background-color: #043985;
  }
  .downmenu {
    border-top: 1px solid #fff;
    height: 181px;
  }
  .nav-down {
    overflow: hidden;
    animation: mymove 0.5s;
  }
  @keyframes mymove {
    from {
      height: 0px;
    }
    to {
      height: 181px;
    }
  }
}

.hecact {
  position: absolute;
  left: 45px;
  top: 0;
}
.head-top {
  position: relative;
  padding: 0 200px 0 85px;
  height: 49px;
  background: #f4f3f3;
  border-bottom: 3px solid #000;
}
.hecact li {
  z-index: 9999;
  margin-right: 12px;
  cursor: pointer;
  float: left;
  line-height: 60px;
  position: relative;
}
/* .hecact li .fa, .hecact li .iconfont {
    color: #7a7a7a;
    font-size: 18px;
} */

.fa-phone:before {
  content: url(../../assets/css/svg/phone.svg);
}
.fa-envelope:before {
  content: url(../../assets/css/svg/email.svg);
}
.email {
  color: #fff;
}
.email:hover {
  color: rgb(237, 128, 10);
}
.hescroll {
  float: left;
  width: 100%;
  /* overflow: hidden; */
  /* padding: 0 50px; */
  position: relative;
}
/* .hesnext, .hesprev {
  cursor: pointer;
  display: block;
  position: absolute;
  top: 0;
  width: 30px;
  height: 46px;
  line-height: 46px;
  text-align: center;
} */
/* .hesprev {
  left: 0;
}
.hesnext {
  right: 0;
} */
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 46px;
  margin: 0;
}
/deep/.el-carousel__container {
  height: 46px;
}

@media screen and (max-width: 748px) {
  .head-top {
    padding: 0 80px;
  }
  .hecact {
    left: 20px;
  }
}
.search {
    background: #fff;
    margin-left: 12px;
    position: absolute;
    top: 11px;
    right: 40px;
    border: 1px solid #c1c1c1;
    height: 24px;
}
.searchtxt {
    width: 145px;
    float: left;
    height: 100%;
    padding-left: 6px;
}
.searchbtn {
    cursor: pointer;
    display: block;
    width: 30px;
    height: 100%;
    background: url(@/assets/image/search.png) center center no-repeat;
}

@media screen and (max-width: 748px) {
  .search {
    display: none;
  }
  .pc {
    display: none;
  }
}


.foshare {
  position: absolute;
  right: 260px;
  top: 0;
  padding: 0;
  height: 45px;
  display: flex;
  align-items: center;
  background: #f4f3f3;
  text-align: center;
}
.foshare li {
    padding: 0 5px;
}

.foshare li a {
  display: inline-block;
  width: 39px;
  height: 39px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 1px solid #747474;
  line-height: 37px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  color: #747474;
}
.foshare li a span {
  font-size: 18px;
}
.foshare li a:hover {
  border-color: #ed800a;
  background: #ed800a;
  color: #fff;
}

@media screen and (max-width: 1280px) {
  .foshare {
    display: none;
  }
}
</style>
