<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="headbot clear">
    <div class="pc">
      <a @click="jump('/')" class="logo">
        <img src="../../assets/image/logo.jpg" alt="" />
      </a>
      <div class="tname">Shenzhen Yagu Dental Technology Co., Ltd.</div>
      <ul class="navlist" :style="theStyle">
        <li>
          <a @click="jump('/')">HOME</a>
        </li>
        <li id="maxScreen" class="dropdown" @mouseover="mouseover" @mouseleave="mouseleave">
          <a @click="jump('/product')">PRODUCT</a>
          <div v-if="showProductDrop" style="position:absolute; left:-28px;z-index: 999;display: flex;">
            <ul ref="categoryRef" class="pcSubmenu nav0">
              <li 
                class="side_nav1"
                v-for="(item, index) of $store.state.categoryLists.categoryList" :key="index"
                :ref="'categoryRef' + index"
                @mouseover="showChild(item.children, index, item)"
              >
                <a @click="jump('/product?categoryId=' + item.id, item, 'pc')">{{ item.category }}</a>
              </li>
            </ul>
            <div class="rigthChildList">
              <ul ref="categoryChildRef">
                <li class="side_nav1" v-for="(item, index) of childrenCategoryList" :key="index">
                  <a @click="jump('/product?categoryId=' + item.id, item, 'pc')">{{ item.category }}</a>
                </li>
              </ul>
            </div>
          </div>
        </li>

        <li>
          <a @click="jump('/contactUs')">CONTACT US</a>
        </li>
        <li>
          <a @click="jump('/feedBack')">FEEDBACK</a>
        </li>
        <li>
          <a @click="jump('/about')">ABOUT US</a>
        </li>
      </ul>
      <!-- <more class="moreIcon" :navindex="navindex" @changeStyle="changeStyle"/> -->
    </div>
    <div class="mobile">
      <a @click="jump('/')">
        <img src="../../assets/image/logo.jpg" alt="" />
      </a>
      <div class="head-search">
        <input type="text" class="searchtxt" name="lname" v-model="productName" />
        <input type="button" name="button" class="searchbtn" value="" @click="PSearch()" id="btnSearch" />
      </div>
      <div class="head-btn" :class="isOpen ? 'is-open' : ''" @click="clickHemenu">
        <span></span><span></span><span></span>
      </div>
      <nav class="head-nav" :class="isOpen ? 'is-active' : ''">
        <ul id="nav" class="site_847689">
          <li class="list-item clearfix">
            <a @click="jump('/')" class="list-item-url">Home</a>
          </li>
          <li class="list-item clearfix">
            <a @click="jump('/about')" class="list-item-url">ABOUT US</a>
          </li>
          <!-- 产品跳转 -->
          <li class="list-item clearfix" id="liproducts" :class="isShowProduct ? '' : 'is-folded'" @click="showProduct">
            <i class="arrow"></i>
            <a @click="jump('/product')" class="list-item-url">PRODUCT</a>
            <ul class="submenu" :style="{display:(isShowProduct ? 'block':'none')}">
              <li
                class="side-nav clearfix"
                v-for="(item, index) of $store.state.categoryLists.categoryList"
                :key="index"
              >
                <a @click="jump('/product?categoryId=' + item.id, item, 'mobile')">{{ item.category }}</a>
                <template v-if="item.children">
                  <ul v-for="(k, i) in item.children" :key="i" class="submenu-three">
                    <li class="side-nav clearfix">
                      <a @click="jump('/product?categoryId=' + k.id, k, 'mobile', item)">{{ k.category }}</a>
                    </li>
                  </ul>
                </template>
              </li>
            </ul>
          </li>

          <li class="list-item clearfix">
            <a @click="jump('/contactUs')" class="list-item-url">CONTACT US</a>
          </li>
          <li class="list-item clearfix">
            <a @click="jump('/feedBack')" class="list-item-url">FEEDBACK</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
// import { productCategory } from "@/api/home";
// import more from "./more.vue";
export default {
  components: {
    // more,
  },
  data() {
    return {
      productName: '',
      isOpen: false,
      isShowProduct: false,
      theStyle: {},
      showProductDrop: false,
      childrenCategoryList: [],
      parentItem: null
    }
  },
  props: {
    navindex: Number,
  },
  methods: {
    PSearch() {
      this.$router.push("/product?productName=" + this.productName)
    },
    clickHemenu() {
      this.isOpen = !this.isOpen
    },
    showProduct() {
      this.isShowProduct = !this.isShowProduct
    },
    changeStyle (type) {
      if (type) {
        this.theStyle = {
          display: 'block'
        }
      } else {
        this.theStyle = {
          display: 'none'
        }
      }
    },
    mouseover () {
      this.showProductDrop = true
    },
    mouseleave () {
      this.showProductDrop = false
      this.childrenCategoryList = []
    },
    /**
     * 
     * @param {*} name 跳转的地方
     * @param {*} item 用来比较是父的还是子的
     * @param {*} type 判断是pc还是手机
     */
    jump (name, item, type, parent) {
      this.isOpen = false
      const breadcrumb = []
      if (item) {
        if (type === 'pc') {
          if (item.id !== this.parentItem.id) {
            breadcrumb.push(this.parentItem)
            breadcrumb.push(item)
          } else {
            breadcrumb.push(item)
          }
        } else {
          if (parent) {
            breadcrumb.push(parent)
            breadcrumb.push(item)
          } else {
            breadcrumb.push(item)
          }
        }
      }
      this.$store.dispatch('categoryLists/saveBreadcrumbPro', breadcrumb)
      this.$router.push(name)
    },
    showChild (data, index, item) {
      this.parentItem = item || null
      this.$refs.categoryChildRef.style.height = this.$refs.categoryRef.offsetHeight
      this.$store.state.categoryLists.categoryList.forEach((item, idx) => {
        this.$refs['categoryRef' + idx][0].style.color = '#373937'
        this.$refs['categoryRef' + idx][0].style.background = 'none'
      })
      this.$refs['categoryRef' + index][0].style.color = '#fff'
      this.$refs['categoryRef' + index][0].style.background = '#ed800a'
      this.childrenCategoryList = data
    }
  }
}
</script>

<style lang="scss" scoped>
.headbot {
  text-align: center;
  position: relative;
  padding: 0 20px;
  max-width: 1700px;
  margin: 0 auto;
}
.logo {
  display: block;
  margin: 0;
  padding: 10px 0;
  position: relative;
  z-index: 11;
  height: 100px;
  max-height: inherit;
  max-width: 300px;
  img {
    max-height: 100%;
    max-width: 100%;

    // max-height: 80px;
    display: block;
  }
}
.tname {
  position: absolute;
  width: 70%;
  left: 217px;
  text-align: center;
  line-height: 20px;
  top: 35px;
  font-size: 22px;
  font-weight: bold;
}
// @media screen and (max-width: 1050px) {
//   .navlist,
//   .tname {
//     display: none;
//   }
// }
// @media screen and (min-width: 1201px) {
//   .tname {
//     font-size: 30px;
//   }
// }
.navlist {
  position: relative;
  z-index: 9999;
  li {
    position: relative;
    display: inline-block;
    a {
      padding: 0 30px;
      line-height: 75px;
      display: inline-block;
      font-family: popmed;
      font-size: 18px;
      position: relative;
      cursor: pointer;
    }
    a:after {
      content: "";
      bottom: 0;
      height: 3px;
      width: 0;
      background: #ed800a;
      position: absolute;
      left: 50%;
      -webkit-transform: translate(-50%, 0);
      -moz-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
      -webkit-transition: 0.5s;
      -moz-transition: 0.5s;
      transition: 0.5s;
    }
    a:hover {
      color: #ed800a;
    }
    a:hover::after {
      width: 40%;
      transform: translate(-50%, 0);
      -webkit-transition: 0.5s;
      -moz-transition: 0.5s;
      transition: 0.5s;
    }
  }
}


.pcSubmenu {
    // position: absolute;
    // left: -28px;
    background: rgba(255,255,255,.8);
    // min-width: 100%;
    // z-index: 999;
    li {
      width: 100%;
      a {
        display: block;
        padding: 0 10px;
        line-height: 36px;
        font-size: 16px;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #373937;
      }
    }
}
// .pcSubmenu li:nth-child(1) a{
//   background: #ed800a;
//   color: #fff;
// }
.pcSubmenu li a:hover {
  background: #ed800a;
  color: #fff;
}
.pcSubmenu li a:after {
  content: none;
}
// .submenu {
//     position: absolute;
//     left: 0;
//     background: rgba(255,255,255,.8);
//     display: none;
//     min-width: 100%;
//     max-width: 400px;
//     z-index: 999;
// }
// .submenu li a {
//     display: block;
//     padding: 0 10px;
//     line-height: 36px;
//     font-size: 16px;
//     text-align: left;
//     white-space: nowrap;
//     text-overflow: ellipsis;
//     overflow: hidden;
//     color: #373937;
// }
.rigthChildList {
    background: rgba(255,255,255,.8);
    // overflow-y: scroll;
    // overflow-x: hidden;
    // width: 200px;
  // position: absolute;
  // top: 0;
  // right: -205px;
  ul {
    // background: rgba(255,255,255,.8);
    // min-width: 100%;
    // z-index: 999;
    li {
      // width: 100%;
      min-width: 200px;
      a {
        display: block;
        padding: 0 10px;
        line-height: 36px;
        font-size: 16px;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #373937;
      }
      :hover {
        background: #ed800a;
        color: #fff;
      }
      :after {
        content: none;
      }
    }
  }
}


// @media screen and (max-width: 1050px) {
//   .navlist {
//     position: absolute;
//     left: 0;
//     width: 100%;
//     background: rgba(255,255,255,.9);
//     box-shadow: 0 4px 9px #999;
//   }
//   .navlist>li {
//       width: 100%;
//       border-bottom: 1px solid #ccc;
//       text-align: center;
//       display: block;
//   }
//   .navlist>li>a {
//     display: block;
//     line-height: 45px;
//     font-size: 17px;
//     white-space: nowrap;
//     text-overflow: ellipsis;
//     overflow: hidden;
//   }
//   .navlist>li>a:after {
//     height: 0;
//   }
//   .nav0{
//     display: block;
//     position: static;
//     li {
//       border-top: 1px solid #dfdfdf;
//       display: block;
//       a {
//         text-align: center;
//         white-space: nowrap;
//         text-overflow: ellipsis;
//         overflow: hidden;
//       }
//     }
//     li:hover {
//       background: #ed800a;
//     }
//     li:hover a {
//       color: #fff;
//     }
//     li>a:after {
//       height: 0;
//     }
//   }

// }

#liproducts {
  /*display:inline-block将对象呈递为内联对象，但是对象的内容作为块对象呈递。旁边的内联对象会被呈递在同一行内，允许空格。*/
  // display: inline-block;
  .dropdown-content {
    display: none;
    position: absolute;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    display: none;
    min-width: 100%;
    max-width: 400px;
    z-index: 999;
  }

  .dropdown-content p {
    display: block;
    padding: 0 10px;
    line-height: 36px;
    font-size: 16px;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: rgb(0, 0, 0);
    text-align: center;
    cursor: pointer;
  }

  .dropdown-content p:hover {
    background: #ed800a;
    color: #fff !important;
  }
}

#liproducts:hover .dropdown-content {
  display: block;
}

.mobile {
  background: #FFF;
  border-bottom: 1px solid #EEE;
  box-sizing: border-box;
  left: 0;
  margin: 0 auto;
  max-width: 750px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  display: none;
  align-items: center;
  height: 55px;
  padding: 0 15px;
  img {
    max-width: 36vw;
    max-height: 40px;
    width: auto;
    height: auto;
  }
}
.head-search {
  flex: 0 0 38vw;
  width: 38vw;
  padding: 0px 10px;
  border: 1px solid #d1d1d1;
  border-radius: 999px;
  height: 30px;
  margin-left: auto;
  outline: 0;
  overflow: hidden;
  position: relative;
}
.head-search,
.head-search .searchtxt {
  max-width: 140px;
}
.head-search .searchtxt {
  border: 0;
  box-sizing: border-box;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  margin: 0px;
  padding: 0px 13px 0px 0px;
  outline: 0;
  width: 38vw;
  background: none;
}
.searchbtn {
  height: 30px;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  background: url(@/assets/image/search.png) center center no-repeat;
}

.head-btn {
  margin-left: 14px;
}
.head-btn {
  height: 30px;
  margin-left: 5vw;
  position: relative;
  width: 30px;
}
.head-btn > span {
  background: #217fe1;
  border-radius: 2px;
  display: block;
  height: 3px;
  position: absolute;
  top: 5px;
  transition: top 0.4s, transform 0.4s;
  width: 24px;
}
.head-btn > span:nth-of-type(2) {
  top: 13px;
}
.head-btn > span:nth-of-type(3) {
  top: 21px;
}
.head-btn.is-open > span {
  top: 14px;
  transform: rotate(45deg);
}
.head-btn.is-open > span:nth-of-type(2) {
  opacity: 0;
}
.head-btn.is-open > span:nth-of-type(3) {
  top: 14px;
  transform: rotate(-45deg);
}

@media screen and (max-width: 768px) {
  .moreIcon {
    display: block;
  }
}
@media screen and (min-width: 768px) {
  .nav-down {
    display: none;
  }
}

@media screen and (max-width: 748px) {
  .pc {
    display: none;
  }
  .mobile {
    display: flex;
  }
  .headbot {
    height: 55px;
    margin: 0 auto;
  }
}
.head-nav.is-active {
    top: 55px;
}
.head-nav.is-active {
    max-height: 100vh;
    position: fixed;
    top: 50px;
    bottom: 0;
    padding-bottom: env(safe-area-inset-bottom);
}
.head-nav.is-active {
    height: auto;
    max-height: calc(100vh - 50px);
    opacity: 1;
    visibility: visible;
    z-index: 200;
}
.head-nav {
    max-width: 750px;
    margin: 0px auto;
    background: #FDFDFD;
    bottom: 0;
    left: 0;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 54px;
    transition: all .3s;
    visibility: hidden;
}
#nav {
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    text-align: left;
}
#nav>li {
    border-bottom: 1px solid #ECECEC;
    position: relative;
}
#nav>li>a {
    font-weight: 700;
    height: 40px;
    line-height: 40px;
    padding-right: 40px;
}

#nav a {
    color: #111;
    display: block;
    font-size: 16px;
    overflow: hidden;
    padding-left: 12px;
    padding-right: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
}
#nav>#liproducts.is-folded .arrow {
    transform: rotate(0);
}
#nav #liproducts .arrow {
    transform: rotate(90deg);
}
#nav .arrow {
    background: url(@/assets/image/arrow.png);
    background-size: 40px 40px;
    display: block;
    height: 40px;
    position: absolute;
    right: 0;
    top: 0;
    transition: transform .3s;
    width: 40px;
}
#nav .submenu {
    border-top: 1px solid #ECECEC;
    background-color: #F8F8F8;
}
#nav .submenu>.side-nav {
    border-bottom: 1px solid #F1F1F1;
}
#nav .submenu li>a {
    height: 35px;
    line-height: 35px;
}
#nav>li#liproducts ul {
    display: block;
}
#nav .submenu ul a {
    font-size: 13px;
    padding-left: 30px;
}

#middleScreen {
  display: none;
}

@media screen and (max-width: 800px) {
  .navlist li a {
    padding: 0 20px;
  }
}

@media screen and (max-width: 1050px) {
  // #maxScreen {
  //   display: none;
  // }
  #middleScreen {
    display: block;
  }
  // .pc {
  //   display: block;
  // }
}

</style>