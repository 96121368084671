<template>
  <div class="innertit">{{ title }}</div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.innertit {
    text-align: center;
    font-size: 36px;
    font-family: goudyBold;
    line-height: 160px;
    margin-bottom: 27px;
    height: 160px;
    background: #bfe4d0;
}
@media screen and (max-width: 748px) {
  .innertit {
    display: none;
  }
}
</style>