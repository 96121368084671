<template>
  <div class="container">
    <div class="main clear">
      <div class="main-inner">
        <!-- <div class="innerprocate">
          <ul class="sf-menu">
            <template v-if="isShowParentCategory">
              <li 
                class="menu-item LiLevel1"
                v-for="item of $store.state.categoryList.categoryList"
                :key="item.id"
              >
                <a @click="jump('/product?categoryId=' + item.id)">{{ item.category }}</a>
                <ul v-if="item.children" class="sub-meu">
                  <li v-for="k of item.children" :key="k.id" class="LiLevel2">
                    <a @click="jump('/product?categoryId=' + k.id)">
                      {{ k.category }}
                    </a>
                  </li>
                </ul>
              </li>
            </template>
            <template v-if="isShowChildCategory">
              <li 
                class="menu-item LiLevel1"
                v-for="item of childrenCategory"
                :key="item.id"
              >
                <a @click="jump('/product?categoryId=' + item.id)">{{ item.category }}</a>
              </li>
            </template>
          </ul>
        </div> -->
        <div class="mobile">
          <div class="tit">
            <h1>Products</h1>
            <div class="arrow-wrap" :class="isDown ? 'is-down' : ''" @click="dropDown"><div class="arrow"></div></div>
          </div>
          <div v-if="isDown">
            <ul class="submenu">
              <li class="side-nav clearfix" v-for="(item, index) of $store.state.categoryLists.categoryList" :key="index">
                <a @click="jump('/product?categoryId=' + item.id)"> » {{ item.category }}</a>
                  <div v-if="item.children" class="prosubmenu">
                    <ul v-for="(k, i) in item.children" :key="i">
                      <li class="side-nav clearfix"><a @click="jump('/product?categoryId=' + k.id)"> » {{ k.category }}</a>
                      </li>
                    </ul>
                  </div>
              </li>
            </ul>
          </div>
        </div>
        <router-view></router-view>
        <!-- <productList ref="productList"/> -->
        <!-- <zoom-img /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { productCategory } from '@/api/home'
export default {
  data() {
    return {
      showPList: true,
      isDown: false,
      isShowParentCategory: true,
      isShowChildCategory: false,
      childrenCategory: []
    }
  },
  watch: {
    '$route.query'(newVal) {
      if (!this.isShowChildCategory) {
        if (newVal.productName) {
          this.isShowParentCategory = false
        } else if (newVal.categoryId) {
          this.isShowParentCategory = false
          this.isShowChildCategory = true
          this.getChildrenCategory(newVal.categoryId)
        } else {
          this.isShowParentCategory = true
          this.isShowChildCategory = false
        }
      }
      // location.reload()
    },
  },
  methods: {
    getChildrenCategory(id) {
      productCategory({ parentId: id }).then(res => {
        this.childrenCategory = res
      })
    },
    dropDown () {
      this.isDown = !this.isDown
    },
    jump (name) {
      this.isDown = false
      this.$router.push(name)
    }
  }
}
</script>

<style scoped lang="scss">
.innerprocate {
  border-bottom: 1px dashed #aaa;
  margin: 10px 0 20px;
  text-align: center;

  .sf-menu li {
    position: relative;
  }
  .sf-menu>li {
    max-width: 25%;
    font-size: 18px;
    padding: 0 20px;
    margin-bottom: 10px;
    display: inline-block;
  }
  .sf-menu>li>a {
    position: relative;
    z-index: 99;
    line-height: 32px;
    padding-bottom: 10px;
    display: block;
    padding-left: 28px;
    background: url(@/assets/image/icon-menu.png) left 7px no-repeat;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
  }
  .sf-menu >li>a:hover {
    color: #ed800a;
    background-image: url(@/assets/image/icon-menu-red.png);
  }
  // .LiLevel1 {
  //   max-width: 25%;
  //   font-size: 18px;
  //   padding: 0 20px;
  //   margin-bottom: 10px;
  //   display: inline-block;
  //   cursor: pointer;
  //   background-image: url(@/assets/image/icon-menu.png);
  //   background-repeat: no-repeat;
  //   background-position-y: 7px;
  //   position: relative;
  //   z-index: 99;
  //   line-height: 32px;
  //   padding-bottom: 10px;
  //   padding-left: 28px;
  //   white-space: nowrap;
  //   // overflow: hidden;
  // }
  // .LiLevel1:hover {
  //   color: #ed800a;
  //   background-image: url(@/assets/image/icon-menu-red.png);
  // }
}
.sf-menu li:hover ul {
    display: block;
}
.sub-meu {
  position: absolute;
  left: 0;
  min-width: 200px;
  box-shadow: 3px 3px 6px #999;
  background: #fff;
  display: none;
  z-index: 9999;
}
.LiLevel2 {
  position: relative;
}
.LiLevel2 a:hover {
  color:#ed800a;
}
.sf-menu li ul li a {
  font-size: 15px;
  display: block;
  padding: 4px 12px 4px 22px;
  line-height: 22px;
  border-bottom: 1px dashed #aaa;
  position: relative;
  text-align: left;
  cursor: pointer;
}
.sf-menu li ul li a:before {
  content: '';
  width: 4px;
  height: 4px;
  background: #666;
  position: absolute;
  left: 7px;
  top: 13px;
}
// .tit {
//   display: none;
// }
.tit {
  border-bottom: 2px solid #E6E6E6;
  margin: 0px 12px;
  padding: 12px 0px;
  position: relative;
}
.tit>h1 {
    color: #217FE1;
    font-size: 18px;
    font-weight: 700;
}
.tit .arrow-wrap {
    position: absolute;
    top: 14px;
    transition: transform 0.3s ease;
    right: 10px;
    width: 24px;
    height: 24px;
    background: #e1e1e1;
    border-radius: 50%;
}
.arrow-wrap.is-down {
    transform: rotate(180deg);
}
.arrow-wrap .arrow {
    width: 7px;
    height: 7px;
    position: absolute;
    top: 5.5px;
    left: 7.5px;
    border-bottom: 2px solid #333;
    border-right: 2px solid #333;
    transform: rotate(45deg);
}

.clearfix {
    zoom: 1;
}
.submenu {
    margin-top: 0;
    position: relative;
    margin-left: 12px;
    // display: flex;
    // flex-wrap: wrap;
}
.submenu>li {
    line-height: 2.3em;
    border-bottom: 1px solid #EEE;
}

.submenu>li {
    width: 100%;
    padding: 0px 10px;
    line-height: 1.8em;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.submenu a {
    font-size: 14px;
    color: #333;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.prosubmenu {
    display: flex;
    flex-wrap: wrap;
}
.prosubmenu ul {
    margin-top: 0;
    position: relative;    
    width: 50%;
}
.prosubmenu ul>li {
    line-height: 2.3em;
}

.prosubmenu ul>li {
    // width: 50%;
    padding: 0px 10px;
    line-height: 1.8em;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.prosubmenu ul a {
    font-size: 14px;
    color: #333;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.mobile {
  display: none;
}
@media screen and (max-width: 748px) {
  .innerprocate {
    display: none;
  }
  // .tit {
  //   display: block;
  // }
  .mobile {
    display: block;
  }
}
</style>