import request from '@/utils/request'

// 滚动文字
export const scrollingText = () => {
  return request({
    url: '/scrolling/text/list',
    method: 'get'
  })
}

// 首页产品
export const productHome = (data) => {
  return request({
    url: '/product/home/list',
    method: 'get',
    params:data
  })
}

// 产品分页
export const productPage = (data) => {
  return request({
    url: '/product/page',
    method: 'get',
    params:data
  })
}

// 产品详情
export const productDetail = (id) => {
  return request({
    url: `/product/detail/${id}`,
    method: 'get'
  })
}

// 公司详情
export const companyDetail = () => {
  return request({
    url: '/company/detail',
    method: 'get'
  })
}

// 首页分类
export const productCategory = (data) => {
  return request({
    url: '/product/category/list',
    method: 'get',
    params: data
  })
}

// 首页分类
export const sendEmail = (data) => {
  return request({
    url: '/contact/send/email',
    method: 'post',
    data
  })
}

// 首页自定义区域
export const selfDetail = () => {
  return request({
    url: '/home/self/detail',
    method: 'get'
  })
}
