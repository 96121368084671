<template>
  <div class="container">
    <div class="main clear">
      <div class="main-inner">
        <p><strong>Introduction</strong></p>
        <br />
        <p>{{ companyData.introduce }}</p>
        <br />
        <p><strong>Environment</strong></p>
        <p v-for="item of companyData.environmentUrls" :key="item">
          <img :src="item" alt="">
        </p>
        <br />
        <p><strong>Certificate</strong></p>
        <p v-for="item of companyData.certificateUrls" :key="item">
          <img :src="item" alt="">
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { companyDetail } from '@/api/home'
export default {
  data() {
    return {
      companyData: {}
    }
  },
  mounted() {
    companyDetail().then(res => {
      this.companyData = res
    })
  }
}
</script>
<style scoped>
img  {
  padding: 5px;
  height: 100%;
  width: 100%;
}
</style>
