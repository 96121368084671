<template>
  <div class="floatwindow_fd_conct">
    <div class="floatwindow_fd_img" style="right: 0px;"><img src="../../assets/image/online-service.png"></div>
    <div class="floatwindow_fd_main" style="right: -268px; opacity: 1;">
      <div class="floatwindow_admin"><img src="../../assets/image/people.png"></div> <span>Online Service</span>
      <ul class="floatwindow_im-tel">
        <li>
          <a @click="jump('/feedBack')" target="_blank" class="floatwindow_online">
            <i></i><span>Online Message</span>
          </a>
        </li>
        <li>
          <a href="mailto:L91327@hotmail.com" title="L91327@hotmail.com" target="_blank" class="floatwindow_email" id="A_9">
            <i></i><span>L91327@hotmail.com</span>
          </a>
        </li>
        <!-- <li>
          <a href="skype:live:.cid.e442f7ce8c313802?chat" title="Lena" target="_blank" class="floatwindow_skype" id="A_7">
            <i></i><span>Lena</span>
          </a>
        </li> -->
        <li>
          <a href="https://api.whatsapp.com/send?l=en&amp;phone=8613631613096" title="8613631613096" target="_blank" class="floatwindow_wechat" id="A_8">
            <i></i><span>8613631613096</span>
          </a>
        </li>
      </ul>
      <div class="floatwindow_phone"><img src="../../assets/image/phone.png"></div>
      <div class="floatwindow_tel-num">+8613631613096</div>
      <!-- <ul class="foshare">
        <li>
          <a href="https://www.linkedin.com/in/%E6%A2%A6%E9%BE%99-%E5%90%B4-a13444227/" target="_blank" rel="nofollow">
            <span class="iconfont">&#xee48;</span>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/home" target="_blank" rel="nofollow">
          <span class="iconfont">&#xe652;</span>
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UC455QBIqneoKhVEduioaIZw" target="_blank" rel="nofollow">
            <span class="iconfont">&#xe73d;</span>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/jameswoo3126/" target="_blank" rel="nofollow">
            <span class="iconfont">&#xe87f;</span>
          </a>
        </li>
      </ul> -->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  mounted() {
    var t;
    var $_len = $('.floatwindow_fd_main').outerWidth() + 5;
    $('.floatwindow_fd_conct').on('mouseenter', function () {
        $('.floatwindow_fd_main').stop().animate({ "right": '0' }, 400, 'swing').css({ opacity: 1 });
        $('.floatwindow_fd_img').stop().animate({ "right": '-50px' }, 200, 'swing'); clearTimeout(t);
    })
    .on('mouseleave', function () {
        t = setTimeout(function (e) {
            $('.floatwindow_fd_main').stop().animate({ "right": -$_len }, 'swing');
            $('.floatwindow_fd_img').stop().animate({ "right": '0' }, 'swing');
        }, 500);
    });
  },
  methods: {
    jump (name) {
      this.$router.push(name)
    }
  }
}
</script>

<style scoped>
.floatwindow_fd_conct {
    position: fixed;
    z-index: 9999999999;
    top: 25%;
    right: 5px;
    cursor: pointer;
    transition: all .3s ease;
    font-size: 14px;
}
.floatwindow_fd_img {
    position: absolute;
    top: 0;
    right: 0;
    width: 42px;
}
.floatwindow_fd_conct img {
    border: none;
    max-width: 100%;
    max-height: 100%;
}
.floatwindow_fd_main {
    position: absolute;
    top: 0;
    right: -170px;
    border: 1px #e5e5e5 solid;
    border-radius: 5px;
    background: #fff;
    opacity: 0;
}
.floatwindow_admin {
    position: absolute;
    top: -48px;
    left: 50%;
    margin-left: -39px;
}
.floatwindow_fd_main>span {
    text-align: center;
    font-size: 18px;
    color: #666;
    display: block;
    margin-top: 60px;
    margin-bottom: 15px;
}
.floatwindow_im-tel {
    padding: 0 10px;
}
.floatwindow_im-tel li {
    text-align: center;
    height: 35px;
    margin-bottom: 7px;
}
.floatwindow_im-tel li a {
    display: block;
    width: 100%;
    display: flex;
    background: linear-gradient(#eee, #fefefe);
    background: -webkit-linear-gradient(#eee, #fefefe);
    background: -o-linear-gradient(#eee, #fefefe);
    background: -moz-linear-gradient(#eee, #fefefe);
    border-radius: 5px;
    border: 1px #eee solid;
}

a {
    color: #373937;
    text-decoration: none;
}
.floatwindow_online i {
    background-image: url(@/assets/image/online.png);
}
.floatwindow_email i {
    background-image: url(@/assets/image/email.png);
}
.floatwindow_skype i {
    background-image: url(@/assets/image/skype.png);
}
.floatwindow_wechat i {
    background-image: url(@/assets/image/wechat.png);
}
.floatwindow_im-tel span {
    line-height: 35px;
    padding: 0 5px 0 10px;
    font-size: 15px;
    white-space: nowrap;
}
.floatwindow_im-tel i {
    background-repeat: no-repeat;
    background-size: 20px;
    width: 20px;
    height: 20px;
    background-position: 50%;
    margin-top: 7px;
}
.floatwindow_im-tel i {
    line-height: 35px;
    display: inline-block;
    width: 30px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}
.floatwindow_phone {
    text-align: center;
    margin-top: 15px;
}
.floatwindow_tel-num {
    text-align: center;
    font-size: 18px;
    color: #fd3939;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 5px;
}
.floatwindow_fd_conct .foshare {
    padding: 0 0 15px;
    display: flex;
    justify-content: center;
}
.foshare {
    padding: 50px 0;
}
.floatwindow_fd_conct .foshare li {
    text-align: center;
    padding: 0 5px;
}

.foshare li {
    display: inline-block;
    padding: 0 8px;
}
.floatwindow_fd_conct .foshare li a {
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-color: #0B66C1;
    background: #0B66C1;
}

.foshare li a {
    display: inline-block;
    width: 39px;
    height: 39px;
    border-radius: 50%;
    border: 1px solid #747474;
    line-height: 37px;
}
.floatwindow_fd_conct .foshare li a span {
    color: #fff;
    font-size: 18px;
}
.floatwindow_fd_conct .foshare li:nth-child(2) a {
    border-color: #2D8DC5;
    background: #2D8DC5;
}
.floatwindow_fd_conct .foshare li:nth-child(3) a {
    border-color: #FD0000;
    background: #FD0000;
}
.floatwindow_fd_conct .foshare li:nth-child(4) a {
    border-color: #1278F3;
    background: #1278F3;
}
.floatwindow_fd_conct .foshare li:nth-child(5) a {
    border-color: #E60024;
    background: #E60024;
}

@media screen and (max-width: 768px) {
  .floatwindow_fd_conct {
    display: none;
  }
}
</style>