<template>
  <div class="container">
    <div class="main clear">
      <div class="main-inner">
        <div class="table-responsive">
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tbody>
              <tr class="firstRow">
                <td width="800" valign="">
                  <p>
                    <strong>Shenzhen Yagu Dental Technology Co., Ltd. </strong><br />
                  </p>
                  <p><strong>Contact：</strong>Lena</p>
                  <p><strong>Tel：</strong>+8613631613096</p>
                  <p>
                    <strong>Whatsapp：</strong>
                    <a
                      href="https://api.whatsapp.com/send?phone=+8613631613096&amp;text=Hello"
                      >8613631613096</a
                    >
                  </p>
                  <p>
                    <strong>Email：</strong>
                    <a href="mailto:L91327@hotmail.com" id="A_9"
                      >L91327@hotmail.com</a
                    >
                  </p>
                  <p>
                    <strong>Address：</strong>Rm 1808 Tianle Building, 1021 Buji Road, Dongxiao Street,Luohu Disrtict, Shenzhen,Gungdong,China
                  </p>
                </td>
                <td width="383" valign="">
                  <img
                    src="@/assets/image/lianxi.jpg"
                    title="201903140936314430133_3"
                    alt="201903140936314430133_3"
                    style="float: right"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <send-email/>
      </div>
    </div>
  </div>
</template>

<script>
import sendEmail from '@/pages/common/sendEmail.vue'
export default {
  components: { sendEmail }
}
</script>

<style scoped lang="scss">
.table-responsive {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  margin: 10px 0px;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-touch-action: auto;
  touch-action: auto;
  width: 100%;
}
.table-responsive table {
    font-size: 18px;
    line-height: 1.8em;
}
// .table-responsive table {
//     border-color: #ccc!important;
//     border-right: 1px solid #727272;
//     border-bottom: 1px solid #727272;
// }
// .table-responsive table td {
//     border: 0;
//     border-color: #ccc!important;
//     border-left: 1px solid #727272;
//     border-top: 1px solid #727272;
// }

.table-responsive table td {
    // border: 1px solid #727272;
    padding: 0px 3px;
}
p {
    word-wrap: break-word;
    color: #333;
    font-size: 16px;
    line-height: 1.6;
}
strong {
    font-family: latoreg;
    font-weight: bold;
}
img {
  height: auto!important;
  max-width: 100%;
}
img:not(.initial) {
    transition: opacity .4s,transform .4s ease;
}

#feedbackForm table {
    width: 100%;
    max-width: 600px;
    margin-top: 20px;
    margin: 0 auto;
    text-align: left;
}
.feedbackForm .ftxt {
    width: 10%;
}
.feedbackForm #feedbackForm .lable {
    white-space: nowrap;
}
#feedbackForm .lable {
    padding: 10px 12px 0 0;
    line-height: 30px;
    height: 30px;
    text-align: right;
}
.feedbackForm .fput {
    width: 90%;
}
.feedbackForm #feedbackForm .text {
    width: 100%;
    height: 38px;
    font-size: 15px;
    color: #222;
}

#feedbackForm .text {
    float: left;
    width: 80%;
    height: 26px;
    margin: 16px 0 0 0;
    font-size: 12px;
    line-height: 26px;
    padding: 0 5px;
    border: 1px solid #ccc;
    font-family: Arial,Helvetica,sans-serif;
    color: #666;
}
#feedbackForm span.xh, #msg {
    color: red;
}
.feedbackForm #feedbackForm .atextarea {
    width: 100%;
    font-size: 15px;
    height: 120px;
}

#feedbackForm .atextarea {
    float: left;
    width: 80%;
    padding: 5px;
    margin: 16px 0 0 0;
    border: 1px solid #ccc;
    height: 100px;
    font-size: 12px;
    line-height: 20px;
    font-family: Arial,Helvetica,sans-serif;
    color: #666;
}
.feedbackForm #feedbackForm .fsbtn {
    width: 100%;
}

#feedbackForm .fsbtn {
    text-align: center;
    width: 80%;
    padding: 1px;
    margin: 0;
}
.feedbackForm #feedbackForm .submita {
    background: #c50012;
    padding: 0 35px;
    height: 40px;
    line-height: 40px;
}

#feedbackForm .submita {
    display: inline-block;
    padding: 0 20px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    font-size: 16px;
    background: #ff9c00;
    margin: 20px 14px;
    border: none;
    color: #fff;
    cursor: pointer;
}
#feedbackForm .submita {
    background: #ed800a!important;
}
</style>
