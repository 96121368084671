<template>
  <div>
    <ul class="main-title">
      <li class="nav-item" :class="path === '/' ? 'active' : ''"><a @click="jump('/')"><i class="icon icon-home"></i><span>Home</span></a></li>
      <li class="nav-item" :class="path === '/about' ? 'active' : ''"><a @click="jump('/about')"><i class="icon icon-about"></i><span>About Us</span></a></li>
      <li class="nav-item" :class="path === '/product' ? 'active' : ''"><a @click="jump('/product')"><i class="icon icon-product"></i><span>Products</span></a></li>
      <li class="nav-item" :class="path === '/contactUs' ? 'active' : ''"><a @click="jump('/contactUs')"><i class="icon icon-contact"></i><span>Contact Us</span></a></li>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
      path: '/'
    }
  },
  mounted() {
    this.path = this.$route.path
  },
  methods: {
    jump (name) {
      this.$router.push(name)
    }
  }
}
</script>

<style scoped lang="scss">
.main-title {
  background-image: linear-gradient(to right, #217FE1, #1F9BED);
  display: none;
}
.main-title .nav-item {
    -ms-flex: 1;
    flex: 1;
    padding: 5px 0;
}
.main-title .nav-item.active span {
    color: #FFF;
}

.main-title .nav-item span {
    color: #FFF;
    display: block;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.3em;
    margin-top: 4px;
    text-align: center;
    max-height: 1.3em;
    overflow: hidden;
}
.main-title .nav-item.active {
    background: #2487EF;
}
.main-title .icon {
    background: url(@/assets/image/css_sprites.png);
    background-size: 300px 200px;
    display: block;
    height: 25px;
    margin: 0 auto;
    width: 25px;
}
.main-title .nav-item.active .icon-home {
    background-position: 0px -100px;
}

.main-title .icon-home {
    background-position: 0px -50px;
}
.main-title .nav-item.active .icon-about {
    background-position: -50px -100px;
}
.main-title .icon-about {
    background-position: -50px -50px;
}
.main-title .nav-item.active .icon-product {
    background-position: -100px -100px;
}
.main-title .icon-product {
    background-position: -100px -50px;
}
.main-title .nav-item.active .icon-contact {
    background-position: -200px -100px;
}
.main-title .icon-contact {
    background-position: -200px -50px;
}

@media screen and (max-width: 748px) {
  .main-title {
    display: flex;
  }
}

</style>