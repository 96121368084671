<template>
  <div>
    <Home-Nav></Home-Nav>
    <Home-title :navindex="3"/>
    <Home-banner title="FEEDBACK"/>
    <Breadcrumb />
    <Main />
    <Home-Footer></Home-Footer>
  </div>
</template>

<script>
import HomeNav from '../common/Nav'
import HomeTitle from '../common/Title'
import Breadcrumb from '../common/Breadcrumb'
import Main from './components/Main'
import HomeBanner from '../common/HomeBanner'
import HomeFooter from '../common/Footer'

// import axios from 'axios'
// import url from '../../modules/api.js'
export default {
  components: {
    HomeNav, HomeTitle, HomeFooter, Main, Breadcrumb, HomeBanner
  },
  data () {
    return {
    }
  },
  mounted () {
  }
}
</script>

<style scoped>
</style>
