<template>
  <div class="container">
    <div class="main clear">
      <div class="main-inner">
        <p>If you have any enquiry about quotation or cooperation, please feel free to email us at <a href="mailto:L91327@hotmail.com" target="_blank" id="A_9">L91327@hotmail.com</a>or use the following enquiry form. Our sales representative will contact you within 24 hours. Thank you for your interest in our products.</p>
        <br>
        <send-email/>
      </div>
    </div>
  </div>
</template>

<script>
import sendEmail from '@/pages/common/sendEmail.vue'
export default {
  components: { sendEmail }
}
</script>

<style scoped lang="scss">
</style>
