<template>
  <div class="container">
    <h1>{{ title }}</h1>
    <div class="card-deck services">
      <div class="card" v-for="item in list" :key="item.id" @click="clickProduct(item.productId)">
        <img class="w-100" :src="item.mainUrl" width="422" height="254"/>
        <div class="hotmore">
          <span>{{item.productName ? item.productName : '商品名字'}}</span>
          <i class="el-icon-arrow-right"></i>
          <!-- <h5 class="card-title font-weight-bold">{{item.title ? item.title : '商品名字'}}</h5> -->
          <!-- <p class="card-text">{{item.title ? item.title : '商品名字'}}</p> -->
        </div>
        <p class="card-title">{{item.productName ? item.productName : '商品名字'}}</p>
      </div>
    </div>
    <swiper class="swiper" :options="swiperOption">
      <template class="swiper-container">
        <swiper-slide v-for="item in list" :key="item.id">
          <img class="w-100" :src="item.mainUrl" @click="clickProduct(item.productId)"/>
          <div class="hotmore" @click="clickProduct(item.productId)">
            <span>{{item.productName ? item.productName : '商品名字'}}</span>
            <i class="el-icon-arrow-right"></i>
            <!-- <h5 class="card-title font-weight-bold">{{item.title ? item.title : '商品名字'}}</h5> -->
            <!-- <p class="card-text">{{item.title ? item.title : '商品名字'}}</p> -->
          </div>
          <p class="card-title">{{item.productName ? item.productName : '商品名字'}}</p>
        </swiper-slide>
        <div class="hotprev tprev swiper-button-prev" slot="button-prev">
          <i class="el-icon-arrow-left"></i>
        </div>
        <div class="hotnext tnext swiper-button-next" slot="button-next">
          <i class="el-icon-arrow-right"></i>
        </div>
      </template>
    </swiper>
  </div>
</template>
<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/css'
export default {
  components: {
    swiper,
    swiperSlide
  },
  props: {
    list: Array,
    title: String
  },
  data () {
    return {
      swiperOption: {
        // noSwiping: true,
        slidesPerView: 3,
        // loop: true,
        // slidesPerView: 3,
        spaceBetween: 30,
        // slidesPerGroup: 3,
        // loopFillGroupWithBlank: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  methods: {
    clickProduct(id) {
      sessionStorage.setItem("productDetail", [JSON.stringify(id)])
      this.$router.push('/productDetail')
    }
  }
}
</script>
<style scoped lang="scss">
.container {
  max-width: 1450px;
  margin: 0 auto;
  margin-top: 50px;
}
h1 {
  font-size: 20px;
  padding: 40px 0 20px;
}
.swiper {
  display: block;
}
@media screen and (min-width: 748px) {
  .card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
  .card-deck {
    display: none;
  }
}
@media screen and (max-width: 748px) {
  .swiper {
    display: none;
  }
  .card-deck .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
  }
  .container {
    margin-top: 0;
  }
} 
.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  margin: 0 20px;
  margin-bottom: 20px;
  cursor: pointer;
} 
.w-100 {
  width: 100% !important;
  // object-fit: revert;
  height: 100% !important;
  // max-width: 100% !important;
  // max-height: 100% !important;
  // min-width: 100% !important;
  // min-height: 100% !important;
  box-sizing: border-box;
  cursor: pointer;
  // border: 1px solid #e9e9e9;
  /* transition: all 0.6s; */
}
/* .w-100:hover {
  transform: scale(1.02);
} */

img {
  vertical-align: middle;
  border-style: none;
}
.text-center {
  text-align: center !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
// .card-title {
//   font-size: 18px;
//   margin-top: 20px;
//   margin-bottom: 10px;
// }
.card-text:last-child {
  margin-bottom: 0;
}
.card-text {
  padding-bottom: 30px;
}
.hotmore {
  display: block;
  opacity: 0;
  line-height: 46px;
  background: #000;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-family: popmed;
  text-transform: uppercase;
  position: relative;
  -webkit-transition: .4s;
  -moz-transition: .4s;
  transition: .4s;
  cursor: pointer;
  span {
    padding-right: 40px;
    display: block;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    transition: .4s;
  }
  i {
    font-size: 20px;
    line-height: 46px;
    width: 40px;
    height: 46px;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    background: #343434;
    font-size: 14px;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    transition: .4s;
  }
}
.card-title {
  display: none;
  color: #333;
  font-size: 16px;
  line-height: 20px;
  margin: 6px 0;
  max-height: 40px;
  min-height: 20px;
  overflow: hidden;
  text-align: center;
}
@media screen and (max-width: 748px) {
  .hotmore {
    display: none;
  }
  .card-title {
    display: block;
  }
}
.swiper-slide:hover .hotmore {
  opacity: 1;
  transition: 0.4s;
}
.hotmore:hover span {
  color: #fff;
  background: #c50012;
  -webkit-transition: .4s;
  -moz-transition: .4s;
  transition: .4s;
  background: #ed800a;
}
.hotmore:hover i {
  background: #d97101;
}
.hotprev {
    left: 00px;
}
.hotnext {
    right: 0px;
}
.hotnext, .hotprev {
    cursor: pointer;
    display: block;
    width: 50px;
    height: 50px;
    background: #d0d0d0;
    line-height: 50px;
    text-align: center;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    transition: .4s;
    z-index: 9999;
}

.hotnext i, .hotprev i {
    font-size: 22px;
    color: #fff;
}
.swiper {
  .swiper-slide {
    // margin: 0!important;
    // margin: 0 15px!important;
  }
  // &:first-child {
  //   margin-left: 0!important;
  // }
}
.swiper-container {
  padding: 0 20px;
}
</style>
