<template>
  <div class="innercontent">
    <div class="inrig clear">
      <div class="inprolist">
        <div class="inprone" v-for="item in productList" :key="item.id">
          <div class="iplef"><img :src="item.imageUrlList[0]" alt="" @click="gotoProduct(item.id)"></div>
          <div class="iprotxt">
            <span class="iprtit" @click="gotoProduct(item.id)">{{ item.name }}</span>
            <p class="ipdetail">
              {{ item.introduce }}
            </p>
            <el-button type="priamry" class="iprbtn" @click="gotoProduct(item.id)">Read More</el-button>
          </div>
        </div>
        <!-- <div v-if="!productList.length">This product is not available</div> -->
      </div>
    </div>
    <ul class="main-prolist">
      <li v-for="item in productList" :key="item.id" @click="gotoProduct(item.id)">
        <a class="prolist-item">
          <div class="pro-img-wrap">
            <div class="pro-img">
              <img width="100" height="100" class="lazy entered loaded" :alt="item.name" :src="item.imageUrlList[0]">
              <!-- <div class="pro-load"></div> -->
            </div>
          </div>
          <p class="pro-name">{{ item.name }}</p>
        </a>
      </li>
      <!-- <div v-if="!productList.length">This product is not available</div> -->
    </ul>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        hide-on-single-page
        layout="prev, pager, next"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { productPage } from '@/api/home'
export default {
  data() {
    return {
      total: 0,
      categoryId: null,
      productName: null,
      pagination: {
        size: 10,
        current: 1
      },
      productList: []
    }
  },
  watch: {
    $route(val) {
      this.categoryId = val.query.categoryId || null
      this.productName = val.query.productName || null
      this.getProductPage()
    }
  },
  created() {
    this.categoryId = this.$route.query.categoryId
    this.productName = this.$route.query.productName || null
    this.getProductPage()
  },
  methods: {
    handleSizeChange (currentSize) {
      this.pagination.size = currentSize
      this.getProductPage()
    },
    handleCurrentChange (currentPage) {
      this.pagination.current = currentPage
      this.getProductPage()
    },
    getProductPage() {
      const form = {
        categoryId: this.categoryId,
        name: this.productName,
        size: this.pagination.size,
        current: this.pagination.current
      }
      productPage(form).then(res => {
        this.total = res.total
        this.productList = res.records
      })
    },
    initData(data) {
      this.categoryId = data.categoryId
      this.getProductPage()
    },
    gotoProduct (data) {
      sessionStorage.setItem("productDetail", [JSON.stringify(data)])
      this.$router.push({ path: '/productDetail' })
    }
  }
}
</script>

<style scoped lang="scss">

.pagination {
  text-align: center;
  font-size: 13px;
  margin: 20px 0;
}
.inprone {
  float: left;
  width: 100%;
  padding: 14px 0;
  border-bottom: 1px dashed #ccc;
  .iplef {
    width: 260px;
    display: inline-block;
    border: 1px solid #efefef;
    float: left;
    img {
      max-width: 100%;
      display: block;
      margin: 0 auto;
      cursor: pointer;
    }
  }
  .iprotxt {
    padding-left: 290px;
    padding-top: 15px;
    .iprtit {
      font-size: 19px;
      line-height: 30px;
      margin-bottom: 8px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      max-height: 60px;
      cursor: pointer;
    }
    .iprtit:hover {
      color:#ed800a;
    }
  }
  .ipdetail {
    overflow: hidden;
    color: #696969;
  }
  .iprbtn {
    display: inline-block;
    min-width: 120px;
    line-height: 38px;
    background: #ed800a;
    color: #fff;
    text-align: center;
    padding: 0 10px;
    margin-top: 18px;
  }
  .iprbtn:hover {
    background: #d97101;
  }
}

.main-prolist {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
    padding: 0px 12px;
    display: none;
}
.main-prolist>li {
    border-bottom: 1px solid #EEE;
    box-sizing: border-box;
    padding: 5px;
    width: 50%;
    a {
      color: #000;
    }
}
.main-prolist>li:nth-child(2n) {
  border-left: 1px solid #eee;
}
.main-prolist .pro-img-wrap {
    position: relative;
    width: 100%;
}
.main-prolist .pro-img-wrap:after {
    content: "";
    display: block;
    padding-top: 100%;
}
.main-prolist .pro-img {
    -ms-flex-pack: center;
    -ms-flex-align: center;
    align-items: center;
    bottom: 0;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.main-prolist .pro-img>img {
    height: 100%;
    object-fit: contain;
    width: 100%;
}
img.loaded {
    opacity: 1;
}
img:not(.initial) {
    transition: opacity .4s,transform .4s ease;
}
// .pro-load {
//     animation: rotation 1s linear infinite;
//     border: 3px solid;
//     border-color: #888 #888 #FFF;
//     border-radius: 50%;
//     display: none;
//     display: inline-block;
//     height: 20px;
//     position: absolute;
//     width: 20px;
// }
.main-prolist .pro-name {
    color: #333;
    display: block;
    font-size: 16px;
    line-height: 20px;
    margin: 6px 0;
    max-height: 40px;
    min-height: 20px;
    overflow: hidden;
    text-align: center;
}

@media screen and (max-width: 748px) {
  .inrig {
    display: none;
  }
  .main-prolist {
    display: flex;
  }
}
</style>