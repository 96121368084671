<template>
  <div class="magnify">
    <div class="left_contaner">
      <ImageMagnifier 
        :src="middleImg"
        :zoom-src="middleImg"
        width="400"
        height="400"
        zoom-width="400"
        zoom-height="400"
        :delay-in="300"
      ></ImageMagnifier>
      <div class="carousel">
        <div class="left_arrow arrow" @click="leftArrowClick"></div>
        <div class="show_box">
          <ul class="picture_container" ref="middlePicture">
            <li class="picture_item" @mouseover="tabPicture(item)" v-for="(item, index) in imgList" :key="index">
              <img :src="item" class="small_img" alt="">
            </li>
          </ul>
        </div>
        <div class="right_arrow arrow" @click="rightArrowClick"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageMagnifier from './ImageMagnifier.vue'
// import image from '@/assets/image/DA2D9393-4081-4384-B493-95DA1620C26D.png'
import $ from 'jquery'
export default {
  components: {
    ImageMagnifier
  },
  props: {
    middleImgWidth: {
      default: 400,
      type: Number
    }, // 产品图片宽
    middleImgHeight: {
      default: 400,
      type: Number
    }, // 产品图片高
    thumbnailHeight: {
      default: 100,
      type: Number
    }, // 缩略图容器高度
    imgList: Array, // 图片数据
    zoom: {
      default: 2, // 缩略比例,放大比例
      type: Number
    }
  },
  watch: {
    imgList(val) {
      if (val.length) {
        this.middleImg = val[0]
        this.pictureList = val
      }
    }
  },
  data() {
    return {
      // image,
      pictureList: [],
      middleImg: '', // 图片地址
      middleLeft: 0, // 当前放置小图盒子的定位left值,
      itemWidth: 80, // 缩略图每张的宽度
    }
  },
  created() {
    // if (this.imgList && this.imgList.length) {
    //   this.pictureList = this.imgList
    // }
    // console.log(this.pictureList[0], 7777777777)
    // this.middleImg = this.pictureList[0]
    // 计算缩略图的宽度,默认是显示4张图片,两边箭头的宽度和为50
    this.itemWidth = (this.middleImgWidth-50) / 4
  },
  mounted() {
    this.$nextTick(() => {
      // 容器的高
      const imgWidth = this.middleImgHeight + this.thumbnailHeight + 20
      // 设置容器宽高
      $('.magnify').css({
        width: this.middleImgWidth,
        height: imgWidth
      })
      // 设置缩略图容器高
      $('.carousel').css({
        height: this.itemWidth
      })
      // 设置每个缩略图宽
      $('.picture_item').css({
        width: this.itemWidth,
        height: this.itemWidth,
      })
    })
  },
  methods: {
    // 切换图片
    tabPicture (item) {
      this.middleImg = item
    },
    // 点击左边箭头
    leftArrowClick () {
      if (this.middleLeft < 0) {
        // 每次向右平移一个图片盒子的宽度
        this.middleLeft += this.itemWidth
        $('.picture_container').animate({
          left: this.middleLeft
        }, 500)
      }
    },
    // 点击右边箭头
    rightArrowClick () {
      // 每次向左平移一个盒子的宽度,最多移动的宽度为(图片数组长度-4)*每张缩略图的宽度
      if (this.middleLeft > -this.itemWidth*(this.pictureList.length-4)) {
        this.middleLeft -= this.itemWidth
        $('.picture_container').animate({
          left: this.middleLeft
        }, 500)
      }
    }
  }
}
</script>

<style scoped>
.magnify {
  float:left;
}
.left_contaner {
  float: left;
  width: 100%;
  height: 100%;
}
.left_contaner .carousel {
  width: 100%;
  margin-top: 20px;
  display: -webkit-flex;
}
.left_contaner .carousel .show_box {
  flex: 1;
  overflow: hidden;
  position: relative;
}
.left_contaner .carousel .arrow {
  flex-basis: 25px;
  cursor: pointer;
}
.left_contaner .carousel .left_arrow {
  background: url('@/assets/image/btn_prev.png') no-repeat;
  background-position: center center;
}
.left_contaner .carousel .right_arrow {
  background: url('@/assets/image/btn_next.png') no-repeat;
  background-position: center right;
}
.left_contaner .carousel .picture_container {
  width: 200%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
}
.left_contaner .picture_container .picture_item {
  height: 100%;
  float: left;
  padding: 5px;
  box-sizing: border-box;
}
.left_contaner .picture_container .picture_item:hover {
  border: 2px solid #f2019f;
}
.left_contaner .picture_container .picture_item img {
  width: 100%;
  height: 100%;
}

</style>