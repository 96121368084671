<template>
  <div class="container">
    <div class="bPosition">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item> -->
        <el-breadcrumb-item
          v-for="(item,index) in breadList"
          :key="index"
          @click.native="clickBread(index)"
          :to="{ path: index === breadList.length - 1 ? '' : item.path }"
        >{{item.name}}</el-breadcrumb-item>
      </el-breadcrumb>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadList: []
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    },
    breadcrumbPro () {
      this.getBreadcrumb()
    }
  },
  computed: {
    breadcrumbPro() {
      return this.$store.state.categoryLists.breadcrumbPro
    }
  },
  created() {
    this.getBreadcrumb()
  },
  methods: {
    isHome(route) {
      return route.name === 'home'
    },
    clickBread (index) {
      if (index === this.breadList.length -1) {
        return false
      }
      if (index !== this.breadList.length -1) {
        const breadList = this.breadList
        breadList.splice(index + 1, breadList.length - 1)
        this.breadList = breadList
        
        const breadcrumb = []
        const breadcrumbList = [...breadList]
        if (breadcrumbList.length === 2) {
          breadcrumbList.shift()
        } else if (breadcrumbList.length === 3) {
          breadcrumbList.shift()
          breadcrumbList.shift()
        }
        breadcrumbList.forEach(item => {
          breadcrumb.push({
            id: item.path.split('=')[1],
            category: item.name
          })
        })
        this.$store.dispatch('categoryLists/saveBreadcrumbPro', breadcrumb)
      }
    },
    getBreadcrumb() {
      let matched = this.$route.matched
      //如果不是首页
      if (!this.isHome(matched[0])) {
        matched = [{ path: '/', name: 'home' }].concat(matched)
      }
      matched.forEach((item, index) => {
        if (!item.name) {
          matched.splice(index, 1)
        }
      })
      if (this.$route.path === '/product' || this.$route.path === '/productDetail') {
        const breadcrumbPro = JSON.parse(sessionStorage.getItem('breadcrumbPro'))
        if (breadcrumbPro && breadcrumbPro.length) {
          breadcrumbPro.forEach(item => {
            matched.push({
              path: '/product?categoryId=' + item.id,
              name: item.category
            })
          })
        }
      }
      this.breadList = matched
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  overflow: hidden;
  max-width: 1200px;
  padding: 0 10px;
  font-size: 16px;
  margin: 0px auto;
  background: #FFF;
}
.bPosition {
  width: 100%;
  margin-bottom: 20px;
  background: #f7f7f7;
  padding-left: 14px;
  margin: 0px auto;
  /deep/.el-breadcrumb {
    line-height: 44px;
  }
}
.el-breadcrumb {
  font-size: 16px;
}


@media screen and (max-width: 748px) {
  .container {
    display: none;
  }
}
</style>