<template>
  <div>
    <Home-Nav></Home-Nav>
    <Home-title :navindex="0"/>
    <banner />
    <mainNav />
    <!-- <Home-Swiper></Home-Swiper> -->
    <Home-Product title="Hot Sale" :list="list1"></Home-Product>
    <selfDetailVue :detail="selfDetail.areaFirst"/>
    <Home-Product title="New Product" :list="list2"></Home-Product>
    <selfDetailVue :detail="selfDetail.areaSecond"/>

    <!-- <Home-Product :list="list3"></Home-Product>
    <Home-Partner></Home-Partner>
    <Home-Expert></Home-Expert>
    <Home-Join></Home-Join> -->
    <Home-Footer></Home-Footer>
  </div>
</template>

<script>
import HomeNav from '../common/Nav'
import HomeTitle from '../common/Title'
// import HomeSwiper from './components/Swiper'
import banner from './components/banner'
import selfDetailVue from './components/selfDetail.vue'
import HomeProduct from '../common/Product'
import HomeFooter from '../common/Footer'
import mainNav from '../common/mainNav.vue'
import { productHome, selfDetail } from '@/api/home'

import { productCategory } from '@/api/home'
import { mapActions } from 'vuex'
export default {
  components: {
    HomeNav, HomeTitle, HomeProduct, HomeFooter, banner, mainNav, selfDetailVue
  },
  data () {
    return {
      list1: [],
      list2: [],
      list3: [],
      selfDetail: {
        areaFirst: null,
        areaSecond: null
      }
    }
  },
  mounted () {
    this.getCategoryList()
    productHome({ homeCategory: 'HOT_SALE' }).then(res => {
      this.list1 = res
    })
    productHome({ homeCategory: 'NEW_PRODUCT' }).then(res => {
      this.list2 = res
    })
    selfDetail().then(res => {
      this.selfDetail = res
      console.log(this.selfDetail)
    })
    // axios.get(url.productLists).then(res => {
    //   this.list1 = res.data.list
    // })
    // axios.get(url.productLists).then(res => {
    //   this.list2 = res.data.list
    // })
    // axios.get(url.productLists).then(res => {
    //   this.list3 = res.data.list
    // })
  },
  methods: {
    ...mapActions([
      'saveCategoryList'
    ]),
    getCategoryList() {
      const that = this
      productCategory({ parentId: 0 }).then((res) => {
        this.productDrop = res
      }).then(() => {
        let arr = []
        this.productDrop.forEach((item) => {
          arr.push(new Promise((resolve) => {
            productCategory({ parentId: item.id }).then(response => {
              item.children = response
              resolve()
            })
          }))
          
        })
        Promise.all(arr).then(() => {
          that.$store.dispatch('categoryLists/saveCategoryList', that.productDrop)
        })
      })
    },
  }
}
</script>
