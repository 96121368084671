<template>
  <div class="banner-wrap">
    <div class="wrapper">
      <el-carousel indicator-position="outside" :interval="3000" arrow="none" :autoplay="true">
        <el-carousel-item v-for="(item, index) in list" :key="item.id">
          <img :ref="'imgRef' + index" class="bannerimg" :src="item.mainUrl" @click="clickProduct(item.productId)"/>
          <!-- <h3 class="scrolling-text">{{ item }}</h3> -->
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
// swiper options example:
import { productHome } from '@/api/home'
export default {
  data () {
    return {
      list: []
    }
  },
  created () {
    productHome({ homeCategory: 'ROLL_PIC' }).then(res => {
      this.list = res
    })
  },
  methods: {
    clickProduct(id) {
      // await productDetail(id).then(res => {
      //   sessionStorage.setItem('breadcrumbPro', JSON.stringify(res.categoryList))
      // })
      sessionStorage.setItem("productDetail", [JSON.stringify(id)])
      this.$router.push('/productDetail')
    }
  }
}
</script>
<style scoped lang="scss">
.banner-wrap {
  // display: flex;
  // justify-content: center;
  position: relative;
  margin: 0 auto;
  padding: 0;
  // height: 450px;
  // overflow: hidden;
  // position: relative;
}
.wrapper {
  width: 1920px;
  max-width: 100%;
  height: 450px;
  // max-height: 100%;
  // width: 100%;
  // height: 100%;
  // position: absolute;
  // top: 0;
  // z-index: 0;
}
.bannerimg {
  width: 100%;
  // height: 100%;
  height: 450px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: over;
  cursor: pointer;
}
// .scrolling-text {
//   text-align: center;
//   line-height: 49px;
// }
/deep/.el-carousel__container {
  height: 100%;
  height: 450px;
}
/deep/.el-carousel__arrow:hover{
  background: #000;
  // color: #ed800a;
}
/deep/.el-carousel__arrow {
  color:#fff;
  background: #666;
  border-radius: 0;
  height: 3.8125em;
  width: 2.375em;
}
/deep/.el-carousel__indicators--outside {
  display: none;
}


@media screen and (max-width: 748px) {
  .wrapper {
    height: 22vh;
  } 
  // .banner-wrap {
  //   height: 22vh;
  // }
  /deep/.el-carousel {
    height: 22vh;
  }
  /deep/.el-carousel__container {
    height: 22vh;
  }
  .bannerimg {
    height: 22vh;
  }
}
</style>
