<template>
  <div class="feedbackForm" id="F1" name="F1">
    <form name="feedbackForm" action="" id="feedbackForm">
      <table
        id="tableInquiry"
        border="0"
        align="center"
        cellspacing="0"
        cellpadding="0"
      >
        <tbody>
          <tr>
            <td class="ftxt"><div class="lable">Your Name:</div></td>
            <td class="fput">
              <input
                id="SubmitName"
                name="SubmitName"
                class="text"
                maxlength="30"
                v-model="name"
              />
            </td>
          </tr>
          <tr>
            <td>
              <div class="lable"><span class="xh">*</span>E-mail:</div>
            </td>
            <td>
              <input
                id="SubmitEmail"
                name="SubmitEmail"
                class="text"
                maxlength="50"
                placeholder="Please input your email here, it is very important!"
                v-model="email"
              />
            </td>
          </tr>
          <tr>
            <td>
              <div class="lable"><span class="xh">*</span>E-mail Confirm:</div>
            </td>
            <td>
              <input
                id="SubmitEmailConfirm"
                name="SubmitEmailConfirm"
                class="text"
                maxlength="50"
                v-model="emailComfirm"
              />
            </td>
          </tr>
          <tr>
            <td valign="top">
              <div class="lable"><span class="xh">*</span>Content::</div>
            </td>
            <td>
              <textarea
                name="SubmitContent"
                id="SubmitContent"
                class="atextarea"
                cols="50"
                rows="5"
                maxlength="2000"
                placeholder="Welcome to Shenzhen Yagu Dental Technology Co., Ltd"
                v-model="content"
              ></textarea>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <div class="fsbtn">
                <button
                  class="submita"
                  id="ImgSend"
                  onclick="return false;"
                  @click="toSend"
                  return
                >
                  Send</button
                ><span id="msg"></span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</template>

<script>
import { sendEmail } from '@/api/home'
export default {
   data() {
    return {
      name: '',
      email: '',
      emailComfirm: '',
      content: ''
    }
  },
  methods: {
    toSend () {
      const { name, email, emailComfirm, content } = this
      if (!email) return this.$message.error('Please enter the email')
      const reg = /^(\w)+(\.\w+)*@(\w)+((\.\w{2,3}){1,3})$/
      if (!reg.test(email)) return this.$message.error('Please enter a valid email format')
      if (email !== emailComfirm) return this.$message.error('Two email inconsistencies')
      if (!content) return this.$message.error('Please enter the content!')
      const form = {
        name,
        email,
        content
      }
      sendEmail(form).then(() => {
        this.$message.success('Sent successfully, we will contact you soon!')
      })
    }
  }
 
}
</script>

<style scoped>
#feedbackForm table {
    width: 100%;
    max-width: 600px;
    margin-top: 20px;
    margin: 0 auto;
    text-align: left;
}
.feedbackForm .ftxt {
    width: 10%;
}
.feedbackForm #feedbackForm .lable {
    white-space: nowrap;
}
#feedbackForm .lable {
    padding: 10px 12px 0 0;
    line-height: 30px;
    height: 30px;
    text-align: right;
}
.feedbackForm .fput {
    width: 90%;
}
.feedbackForm #feedbackForm .text {
    /* width: 100%; */
    height: 38px;
    font-size: 15px;
    color: #222;
}

#feedbackForm .text {
    float: left;
    width: 80%;
    height: 26px;
    margin: 16px 0 0 0;
    font-size: 12px;
    line-height: 26px;
    padding: 0 5px;
    border: 1px solid #ccc;
    font-family: Arial,Helvetica,sans-serif;
    color: #666;
}
#feedbackForm span.xh, #msg {
    color: red;
}
.feedbackForm #feedbackForm .atextarea {
    /* width: 100%; */
    font-size: 15px;
    height: 120px;
}

#feedbackForm .atextarea {
    float: left;
    width: 80%;
    padding: 5px;
    margin: 16px 0 0 0;
    border: 1px solid #ccc;
    height: 100px;
    font-size: 12px;
    line-height: 20px;
    font-family: Arial,Helvetica,sans-serif;
    color: #666;
}
.feedbackForm #feedbackForm .fsbtn {
    width: 80%;
}

#feedbackForm .fsbtn {
    text-align: center;
    width: 80%;
    padding: 1px;
    margin: 0;
}
.feedbackForm #feedbackForm .submita {
    background: #c50012;
    padding: 0 35px;
    height: 40px;
    line-height: 40px;
}

#feedbackForm .submita {
    display: inline-block;
    padding: 0 20px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    font-size: 16px;
    background: #ff9c00;
    margin: 20px 14px;
    border: none;
    color: #fff;
    cursor: pointer;
}
#feedbackForm .submita {
    background: #ed800a!important;
}
</style>
