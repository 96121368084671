import Vue from "vue"
import Vuex from "vuex"
Vue.use(Vuex)

const state = {
  categoryList: JSON.parse(sessionStorage.getItem('categoryList')) || [],
  breadcrumbPro: JSON.parse(sessionStorage.getItem('breadcrumbPro')) || []
}

const mutations = {
  SAVE_CATEGORY_LIST(state, categoryList){
    state.categoryList = categoryList
  },
  SAVE_BREADCURMB_PRO(state, breadcrumbPro) {
    state.breadcrumbPro = breadcrumbPro
  }
}

const actions = {
  saveCategoryList({ commit }, data) {
    sessionStorage.setItem('categoryList', JSON.stringify(data))
    commit('SAVE_CATEGORY_LIST', data)
  },
  saveBreadcrumbPro({ commit }, data) {
    sessionStorage.setItem('breadcrumbPro', JSON.stringify(data))
    commit('SAVE_BREADCURMB_PRO', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}